import * as React from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
//import { mapListData } from '../../data/mapList.js';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ImageIcon from '@mui/icons-material/Image';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CopyableCode from '../../components/CopyableCode.js';
import TwoButtonToggle from '../../components/twoButtonToggle.js';
import OneButtonToggle from '../../components/oneButtonToggle.js';

const MapListText = ({ setOpenSnackbar, mapListData, hideBadMapsHandler, hideBadMapsActive }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const selectedModeTypes = {speedrun: "Speedrun", competitive: "Competitive"}
  const [selectedMode, setSelectedMode] = useState(null);

  const handleModeToggle = (inputString) => {
    setSelectedMode(inputString)
  };

  // const setButtonColor = (inputString) => {
  //   const index = selectedMode.indexOf(inputString);
  //   if (index === -1) {
  //     return "primary"
  //   } else {
  //     return "secondary"
  //   }
  // }

  const toggleTitleMode = (title, introduction) => {
    const introductionParts = introduction.split('\n');
  
    return (
      <Stack>
        <Typography variant='h6'>
          {title}
        </Typography>
        {introductionParts.map((part, index) => (
          <Typography key={index} variant='body'>
            {part}
          </Typography>
        ))}
      </Stack>
    );
  };
  
  const columns = useMemo(
    () => [
      {
        accessorKey: 'image_url',
        header: <ImageIcon/>,
        size: 1,
        enableSorting: false,
        enableGlobalFilter: false,
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              //gap: '1rem',
              cursor: 'pointer',
            }}
            onClick={() => {
              if(selectedRow === row.id){
                  setSelectedRow(null)
              } else {
                  setSelectedRow(row.id)
              }
            }}
          >
            <img
              alt=""
              height={selectedRow === row.id ? 120 : 30}
              src={row.original.image_url}
              loading="lazy"
            />
          </Box>
        ),
      },
      {
        accessorKey: 'title',
        header: 'Title',
        size: 2,
        Cell: ({ row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              //gap: '1rem',
              cursor: 'pointer',
            }}
            onClick={() => {
              if(selectedRow === row.id){
                  setSelectedRow(null)
              } else {
                  setSelectedRow(row.id)
              }
            }}
          >
            {selectedRow === row.id ? toggleTitleMode(row.original.title, row.original.introduction): row.original.title}
          </Box>
        ),
      },
      {
        accessorKey: 'code',
        header: 'Code',
        size: 1,
        enableSorting: false,
        enableColumnActions: false,
        Cell: ({ row }) => (
          <CopyableCode item={row.original} passedPX={"5px"}/>
        ),
      },
      {
        accessorKey: 'mode',
        header: 'Mode',
        size: 1,
      },
      {
        accessorKey: 'creator',
        header: 'Creator',
        size: 1,
      },
    ],
    [selectedRow],
  );

  const data = useMemo(() => {
    if(!selectedMode){
      const filteredData = mapListData.map(item => ({
        ...item,
        tags: item.tags.join(', ') // Convert tags array to comma-separated string
      }));
      return filteredData;
    } else {
      const filteredData = mapListData.filter(item => item.mode.includes(selectedMode)).map(item => ({
        ...item,
        tags: item.tags.join(', ') // Convert tags array to comma-separated string
      }));
      return filteredData;
    }
  }, [mapListData, selectedMode]);
  
  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    enableHiding: false,
    initialState: {
      density: "compact",
      pagination: { pageSize: 50, pageIndex: 0 },
      showGlobalFilter: true,
    },
    state: {
      isLoading: (mapListData.length < 1), //cell skeletons and loading overlay
    },
    muiTableContainerProps: { sx: { maxHeight: '67vh' } },
    muiTableBodyRowProps: ({ row }) => ({
        // onClick: () => {
        //     if(selectedRow === row.id){
        //         setSelectedRow(null)
        //     } else {
        //         setSelectedRow(row.id)
        //     }
        // },
        // sx: {
        //     cursor: 'pointer',
        // },
    }),
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{ 
          display: 'flex',
          gap: '1rem',
          alignItems: 'center'
        }}
      >
        <FilterAltIcon/>
        <TwoButtonToggle 
          button1={selectedModeTypes.competitive}
          button2={selectedModeTypes.speedrun}
          onChange={handleModeToggle}
        />
        <OneButtonToggle
          button1={"Good Maps Only"}
          onChange={hideBadMapsHandler}
          isActive={hideBadMapsActive}
        />
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};

export default MapListText;
